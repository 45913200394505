import React from 'react'
import Props from 'prop-types'

import { isWeb } from 'common/helpers'
import Page from 'components/Page'
import Navigation from 'components/Navigation'
import Container from 'components/Styled/Container'
import { H2, H3, Ol } from 'components//Styled/Typography'
import {
  Section,
  PageContent,
  SECTION_LAYOUT_TEXT,
} from 'components/Styled/Layout'
import { Link } from 'gatsby'

const TermOfUse = ({ pageContext: lang }) => {
  const rawQueryParams = typeof location !== 'undefined' ? location.search : ''

  return (
    <Page title="Terms of Use" translates={lang.lang}>
      <Navigation
        isMobileVisible={isWeb(rawQueryParams)}
        translates={lang.lang}
      />
      <Container>
        <PageContent>
          <H2>Terms of Use</H2>
          <Section layout={SECTION_LAYOUT_TEXT}>
            <H3 numbered>Introduction</H3>
            <Ol>
              <li>
                Cosmic Latte s.r.o. ("Cosmic Latte"), established in accordance
                with the laws of the Czech Republic, with its registered office
                at Rohanské nábřeží 678/23, Karlín, 186 00 Prague 8, Czech
                Republic, ID no. 050 66 891, entered in the Commercial Register
                at the Municipal Court in Prague, Section C, Insert 257791, is
                the exclusive owner, data controller and operator of the Zoe
                application, a dating app for LGBTQ women (the "App").
              </li>
              <li>
                As used in these Terms, "we", "our" and "us" means the Cosmic
                Latte.
              </li>
              <li>
                "Service(s)" refers to your use of the App for any purpose
                whatsoever.
              </li>
              <li>
                "You" refers to the user of this App and its related services,
                and as such, you have gained the right to use this App by
                respecting the applicable Terms of Use (the "Terms") described
                in detail below.
              </li>
              <li>
                These Terms set forth the terms and conditions that apply to
                your use of the App and all services offered by us.
              </li>
              <li>
                These Terms include our{' '}
                <Link to="/privacy-policy">Privacy Policy</Link>, which can be
                accessed at any time at{' '}
                <Link to="/privacy-policy">
                  http://www.zoeapp.co/privacy-policy
                </Link>
              </li>
              <li>
                By completing the subscription process or by using the App, you
                are agreeing to be bound by all of the terms in these Terms
                including Privacy Policy.
              </li>
              <li>
                Please save or print and keep a copy of these Terms for your
                records.
              </li>
            </Ol>

            <H3 numbered>Acceptance of the Terms</H3>
            <Ol>
              <li>
                In order to use the Services, you must firstly agree to the
                Terms. You may not use the Services if you do not accept the
                Terms.
              </li>
              <li>
                You may accept the Terms by (i) creating an account (ii)
                clicking to accept or agree to the Terms, where this option is
                made available to you by us in the user interface for any
                Service, or (iii) by payment for the Services, or (iv) by using
                the App or any Services.
              </li>
              <li>
                You may not use the Services or accept the Terms if (i) you do
                not accept the Terms (ii) you are not of legal age to form a
                binding contract with us, or (iii) you are a person barred from
                receiving the Services under the laws of the Czech Republic or
                other countries, including the country in which you are a
                resident or from which you use the Services.
              </li>
              <li>
                We reserve the right, at our discretion, to change, modify, add,
                or remove portions of these Terms at any time and for any
                reason, including to reflect changes in or requirements of the
                law or new features.
              </li>
              <li>
                We will make the changed Terms available at
                https://www.zoeapp.co/terms-of-use?web and in the App at least
                one month prior to the effectiveness of the changed Terms.
              </li>
              <li>
                If the changes of the Terms include material changes, we will
                notify you of the changes through the App.
              </li>
              <li>
                You understand and agree that if you use the Services after the
                date on which the Terms have changed, we will treat your use as
                acceptance of the changed Terms. If you do not agree to the
                changed Terms, please do not use the Service and delete your
                account from the App.
              </li>
            </Ol>

            <H3 numbered>Eligibility</H3>
            <Ol>
              <li>
                You must be at least 18 years of age or older, if required by
                applicable laws, to create an account, visit or use this App or
                the Services (legal adult).
              </li>
              <li>
                Legal entities and entrepreneurs may not use the Service for any
                purpose except with our express written consent.
              </li>
              <li>
                By visiting the App or accepting these Terms, you represent and
                warrant that you:
              </li>
              <ul>
                <li>
                  (a) are 18 years of age or older, if required by applicable
                  laws
                </li>
                <li>
                  (b) have the right and capacity to agree to and abide by the
                  Terms
                </li>
                <li>
                  (c) will use the App in a manner consistent with all
                  applicable laws (local, national, international), rules and
                  regulations and with these Terms; and
                </li>
                <li>
                  (d) have not been convicted of any felony, indictable offense,
                  or any crime involving violence and are not required to
                  register as a sex offender.
                </li>
              </ul>
            </Ol>

            <H3 numbered>Security</H3>
            <Ol>
              <li>
                You agree and understand that you are responsible for
                maintaining the confidentiality of passwords associated with any
                account you use to access the Services.
              </li>
              <li>
                Accordingly, you agree that you will be solely responsible for
                all activities that occur under your account.
              </li>
              <li>
                If you become aware of any unauthorized use of your password or
                of your account, you agree to notify us immediately via the app
                or on the email{' '}
                <a href="mailto:info@zoeapp.co">info@zoeapp.co</a>.
              </li>
            </Ol>

            <H3 numbered>Interaction with other users</H3>
            <Ol>
              <li>
                You are solely responsible for your interaction with other
                users. Cosmic Latte is not responsible for any behaviours of its
                users.
              </li>
              <li>
                You agree to take reasonable precautions in all interaction with
                other users of the Service, particularly, if you decide to meet
                offline or in person.
              </li>
              <li>
                You should not provide your financial information (for example,
                your credit card or bank account information) to other users.
              </li>
              <li>
                You understand that we currently do not conduct criminal
                background checks or other screening on the users or attempt to
                verify the statements of the users. However, we reserve the
                right to conduct any criminal background check or other
                screenings (such as sex offender register searches), at any time
                and using available public records and other sources. You can
                report inappropriate behaviours via the app or by email{' '}
                <a href="mailto:info@zoeapp.co">info@zoeapp.co</a>.
              </li>
              <li>
                We make no representations or warranties as to the conduct of
                users or their compatibility with any current or future users.
                We are not liable for any damages whatsoever, whether material
                or immaterial, direct, indirect, consequential or incidental,
                arising from or relating to the conduct of you or anyone else in
                connection with the use of the Service, including bodily injury
                or emotional distress.
              </li>
            </Ol>

            <H3 numbered>Content in Profiles</H3>
            <Ol>
              <li>
                You understand that all information such as, without limitation,
                data files, written texts, computer software, music, audio files
                or other sounds, photographs, videos or images (all such
                information being hereinafter referred to as the "Content")
                which you may have access to as part of, or through your use of,
                the Services are the sole responsibility of the person from
                which such Content originated.
              </li>
              <li>
                We do not and cannot review all Content posted or sent by users
                of the Service. We are not responsible for any of the Content.
                After the reporting of inappropriate Content, we are obliged to
                review the Content and remove the inappropriate Content at our
                sole discretion. You can report inappropriate Content via the
                app or by email{' '}
                <a href="mailto:info@zoeapp.co">info@zoeapp.co</a>.
              </li>
              <li>
                You understand that, by using the Services, you may be exposed
                to Content that you may find offensive, indecent or
                objectionable and that, in this respect, you use the Services at
                your own risk.
              </li>
              <li>
                You agree that you are solely responsible for (and that we have
                no responsibility to you or to any third party for) any Content
                that you create, transmit or display while using the Services.
              </li>
              <li>
                You shall indemnify and hold us harmless from all claims and all
                liabilities, costs, proceedings, damages and expenses awarded
                against, or incurred or paid by us as a result of or in
                connection with your breach of the Terms, any third party's
                intellectual property or other rights.
              </li>
            </Ol>

            <H3 numbered>Your use of the Services, Prohibited Behavior</H3>
            <Ol>
              <li>
                You agree to use the Services only for purposes that are
                permitted by (i) the Terms; (ii) any applicable law, regulation,
                generally accepted practices, or guidelines in the relevant
                jurisdictions; and (iii) any other applicable rules (including
                Facebook, Spotify and Instagram rules).
              </li>
              <li>
                You agree that you will not engage in any activity that
                interferes with or disrupts the Services.
              </li>
              <li>
                You will keep all information provided to you through the
                Service private and confidential and will not give such
                information to anyone without the permission of the person who
                provided it to you.
              </li>
              <li>
                You will use the Service for personal use only. Users may not
                use the Service or any Content contained in the Service in
                connection with any commercial endeavours and may not use any
                information obtained from the Service to contact, advertise to,
                solicit, or sell to any other user or request money from the
                user without prior explicit consent of Cosmic Latte.
              </li>
              <li>
                You will not impersonate any person or entity or otherwise
                misrepresent affiliation, connection or association with any
                person or entity.
              </li>
              <li>
                You will not use the Service to engage in any form of harassment
                or offensive behaviour, including but not limited to, the
                posting of communications, pictures or recordings which contain
                nudity or obscene, profane, threatening, intimidating,
                harassing, racist, false, misleading, illegal, libellous,
                slanderous, abusive, offensive or defamatory Content, or racist,
                obscene, or otherwise offensive language, or stalk or otherwise
                harass any person or provide material that exploits people in a
                sexual, violent or other illegal manner.
              </li>
              <li>You will not forward chain letters through the Service.</li>
              <li>
                You will not use the Service to infringe the privacy rights,
                property rights, intellectual property rights, or any other
                rights of any person.
              </li>
              <li>
                You will not post any Content that contains video, audio,
                photographs, images or any personal data of another person
                without his or her permission, or any Content that contains
                restricted or password only access pages.
              </li>
              <li>
                You will not cause the Service to be accessed through any
                automated or robotic means, including but not limited to, rapid
                access of the site as in a denial-of-service attack. Such
                restriction does not apply to legitimate search engine activity
                that does not place an unreasonable burden on the Service.
              </li>
              <li>You may not, when using the Service:</li>
              <ul>
                <li>
                  (a) solicit passwords or personal identifying information for
                  commercial or unlawful purposes from other users
                </li>
                <li>
                  (b) post or promote pirated copyrighted material or links to
                  it
                </li>
                <li>
                  (c) provide or transmit any material that contains viruses,
                  time bombs, trojan horses, cancelbots, worms or other harmful
                  or disruptive codes or devices
                </li>
                <li>
                  (d) post, use, transmit or distribute (e.g. screen scrape) in
                  any manner any of the Content other than solely in connection
                  with your use of the Service
                </li>
                <li>
                  (e) retrieve, frame, mirror or in any way reproduce or
                  circumvent the presentation of the Service or its Content
                </li>
                <li>
                  (f) forge headers or otherwise manipulate identifiers in order
                  to disguise the origin of any information transmitted through
                  the Service
                </li>
                <li>
                  (g) use any code or devices containing any reference to us or
                  the Service to direct any person to any other website for any
                  purpose; or
                </li>
                <li>
                  (h) modify, adapt, sublicense, translate, sell, reverse
                  engineer, decipher, decompile or otherwise disassemble any
                  portion of the software used on or for the Service.
                </li>
              </ul>
              <li>
                Cosmic Latte is committed to maintaining a safe and respectful
                community environment within our apps. To uphold these
                standards, we may take necessary actions such as hiding profiles
                or freezing accounts, if they for example violate our Terms and
                Conditions, violate the Community Standards, engage in
                suspicious activity, etc. These actions are implemented in a
                following way:
                <ul>
                  <li>
                    Hiding and Requiring Verification on Profiles: Profiles may
                    be hidden and required verification manually by the
                    administrator team or automatically based on a variety of
                    triggers. These triggers might include being reported
                    multiple times by other users, suspicious verification
                    history and locale, and etc.
                  </li>
                  <li>
                    Freezing Accounts: Accounts may be frozen in case of serious
                    violations including underage users, fake users, users
                    failing verification multiple times for incorrect photos,
                    spammers or scammers, users that show illegal behavior or
                    inappropriate content, and etc. The freezing might be done
                    manually by the administrator team or automatically.
                  </li>
                </ul>
                If you have any questions or concerns regarding these
                enforcement actions, please contact our customer support team at{' '}
                <a href="mailto:help@zoeapp.co">help@zoeapp.co</a>.
              </li>
              <li>
                We reserve the right, in our sole discretion, to investigate and
                take any legal action against anyone who violates this Section
                or the Terms, including removing the offending communication
                from the Service and terminating or suspending the account of
                such violators. The violators agree to cover any expenses and
                costs related to the investigation and prosecution of such
                activities.
              </li>
            </Ol>

            <H3 numbered>User Experience and Preferences</H3>
            <Ol>
              <li>
                Content Moderation: We employ a combination of advanced
                automated and manual moderation tools and processes to ensure a
                safe environment. This includes scanning new profiles for
                inappropriate content, ongoing monitoring for fraudulent
                activity, and manual reviews of suspicious profiles and user
                reports.
              </li>
              <li>
                User Recommendations: Our App streamlines user interactions by
                requesting the information of distance, age, and the
                verification status of a user. Additionally, when creating
                recommendations, we check if a user was liked by another user
                and qualify that as a potential match.
              </li>
              <li>
                Modifying Preferences: Users have the ability to customize their
                preferences within the App to tailor their experience. This
                includes adjusting settings such as age range, distance
                preferences, verification status preferences and etc. at any
                time through the App's settings menu.
              </li>
            </Ol>

            <H3 numbered>Advertisement</H3>
            <Ol>
              <li>
                Cosmic Latte may use the advertisement in the App for
                self-promotion of the App and Service. We may use your contact
                information for marketing and commercial purposes so that we can
                offer you relevant products and services from us. Thus, we can
                send you a commercial communication (such as an SMS, e-mail,
                etc.) using the electronic contact you provided to us. You are
                entitled at any time to decline this communication
                (announcement).
              </li>
              <li>
                We reserve the right to monitor all advertisements, public
                postings and messages to ensure that they conform to content
                guidelines.
              </li>
              <li>
                In consideration for us granting you access to and use of the
                Services, you agree that we may place advertising on the
                Services.
              </li>
            </Ol>

            <H3 numbered>Proprietary Rights</H3>
            <Ol>
              <li>
                You acknowledge and agree that we own all legal rights, title
                and interest in and to the Services, including any intellectual
                property rights (such as copyrighted material and trademarks)
                which subsist in the Services.
              </li>
              <li>
                You agree to not copy, modify, transmit, create any derivative
                works from, make use of, or reproduce in any way any copyrighted
                material, trademarks, trade names, service marks, or other
                intellectual property or proprietary information accessible
                through the Service, without first obtaining the prior written
                consent of us or, if such property is not owned by us, the owner
                of such intellectual property or proprietary rights.
              </li>
              <li>
                You agree to not remove, obscure or otherwise alter any
                proprietary notices appearing on any Content, including
                copyright, trademark and other intellectual property notices.
              </li>
              <li>
                Cosmic Latte may manage, regulate, control, modify or eliminate
                virtual goods at any time, with or without notice. Cosmic Latte
                shall have no liability to you or any third party in the event
                that Cosmic Latte exercises any such rights.
              </li>
            </Ol>

            <H3 numbered>External Links</H3>
            <Ol>
              <li>
                The Services may include hyperlinks to other web sites or
                Content or resources. We have no control over any web sites or
                resources which are provided by persons (e.g., users) other than
                us.
              </li>
              <li>
                You acknowledge and agree that we are not responsible for any
                such external sites or resources and have no liability as a
                result of the availability of them or their Content.
              </li>
              <li>
                You understand that, by using any such external sites or
                resources, you may encounter Content that may be deemed
                offensive, indecent, or objectionable and may or may not be
                identified as having explicit language, and that the results of
                any search or entering of a particular URL may automatically and
                unintentionally generate links or references to objectionable
                material. You understand and agree that your use of external
                links may result in harmful or unwanted Content or malicious
                software infecting or interacting with your computer or mobile
                device.
              </li>
              <li>
                The personal data you may choose to give to such third parties
                are not covered by our privacy policies. Some third-party
                companies may choose to share their personal data with us, in
                which case, such data sharing is governed by that third party's
                privacy policy.
              </li>
            </Ol>

            <H3 numbered>Memberships and Paid features</H3>
            <Ol>
              <li>
                In addition to the free services provided on the App, Premium
                Memberships and Paid Features, such as Power Message, are
                offered that require payment. Where payment is required, the
                following additional terms and conditions will apply.
              </li>
              <li>
                You expressly acknowledge and agree that the initial price may
                change if you upgrade or change your subscription plan within
                the Service. You will always be informed about the price
                modification prior to making the upgrade or changing your
                subscription.
              </li>
              <li>
                We use payment platforms of third parties (Apple, Google) for
                the payment process. By registering for the Paid Memberships or
                Paid Features and providing your credit card details, you agree
                to be billed by us or by third party in advance and you agree
                with the terms and private policies of this third parties. It is
                your sole obligation to provide accurate and complete credit
                card information, and to timely update your account with any
                changes to such information.
              </li>
              <li>
                Paid Memberships are subscriptions. After your initial
                subscription commitment period, and again after any subsequent
                subscription period, your subscription will automatically
                continue for an additional equivalent period, at the price you
                agreed to when subscribing. You agree that your account will be
                subject to this automatic renewal feature. If you do not wish
                your account to renew automatically, or if you want to change or
                terminate your subscription, please go to your phone settings,
                and opt out of automatic renewal of your purchase. If you opt
                out of automatic renewal after your purchase, you may use your
                subscription until the end of your then-current subscription
                term; your subscription will not be renewed after your
                then-current term expires. You will not be eligible for a
                prorated refund of any portion of the subscription fee paid for
                the then-current subscription period. Paid Memberships cannot be
                transferred to any other account. By subscribing to the Paid
                Membership, you authorize our payment service provider or us to
                bill the credit card you provided now and again at the beginning
                of any subsequent subscription period.
              </li>
              <li>
                Your continued use of the Paid Memberships reaffirms that the
                third parties or we are authorized to charge the credit card you
                provided. We may submit those charges for payment and you will
                be responsible for such charges. This does not waive our right
                to seek payment directly from you. Your charges may be payable
                in advance, in arrears, per usage, or as otherwise described
                when you initially subscribed to the Paid Memberships.
              </li>
              <li>
                Where payment is required for a Paid Membership and we or our
                payment service provider are unable to charge your credit card
                for any reason, we may discontinue any and all Services to you
                either temporarily or permanently.
              </li>
              <li>
                We are not responsible for the payment processing provided by
                any third party.
              </li>
              <li>
                If you cancel your account, your Paid Membership will be
                continued. If you wish to cancel your Paid Membership you must
                go to the settings of your phone and cancel it here. All
                payments for Paid Memberships are non-refundable. You will not
                be eligible for a prorated refund of any portion of the unused
                paid services or to transfer it to another account.
              </li>
              <li>
                Each party will be responsible for payment of any taxes
                applicable to its own income and activities in connection with
                the Services.
              </li>
              <li>
                For the users residing in the European Union or European
                Economic Area. You expressly agree to the providing of our
                services before the expiry of the 14-day period, thereby waiving
                the right to withdraw from this Agreement and you will not
                benefit from a right of withdrawal, and you will lose this right
                in accordance with Article 6 (1) (k) of Directive 2011/83/EU of
                the European Parliament and of the Council of 25 October 2011 on
                consumer rights in conjunction with Article 16 (m) of this
                Directive. This article considers as prior express consent and
                fulfilment of the obligation to provide information about losing
                your right to withdrawal.
              </li>
              <li>
                Objections to payment should be sent directly to our support on{' '}
                <a href="mailto:info@zoeapp.co">info@zoeapp.co</a> or to the
                relevant third party (App Store, Google Play) or to your
                bank/payment provider.
              </li>
              <li>
                The payment processor for our Service is a third-party provider
                such as Apple, Google Play, etc. By making a payment, you agree
                to pass on the necessary payment information to these providers
                and authorize Cosmic Latte or a third-party account, as
                applicable, to charge you the payments for Services.
              </li>
            </Ol>

            <H3 numbered>License from Us</H3>
            <Ol>
              <li>
                We give you a worldwide, royalty-free, non-assignable and
                non-exclusive right and license to access and use the Services
                for the term and in the scope designated by the Terms. This
                license is for the sole purpose of enabling you to use and enjoy
                the benefit of the Services as provided by us in the manner
                permitted by the Terms.
              </li>
              <li>
                Unless we have given you specific written permission to do so,
                you may not assign (or grant a sub-license of) your rights,
                grant a security interest in or over your rights, or otherwise
                transfer any part of your rights granted hereunder.
              </li>
            </Ol>

            <H3 numbered>License from You</H3>
            <Ol>
              <li>
                You retain the copyright and any other intellectual property
                rights you already hold in the Content which you submit, post or
                display on or through the Services.
              </li>
              <li>
                By submitting, posting or displaying the Content on or through
                the Services, you give us a non-exclusive, worldwide,
                royalty-free, transferable and sub-licensable license (the
                "License") to the Content, for independent use and for use in
                connection with other works (including authorization to add the
                Content to collective works), in the original, processed, or
                another altered form, as a whole or any components or parts, in
                each of these cases, for all manners of use and in an unlimited
                scope. The License is provided to us for the entire duration of
                the copyright for all countries of the world, authorizing us and
                other entities that obtain authorization to use the Content from
                us to use the Content in any manner or by any technological
                process (even if such manner or technological process is used in
                the future) in any quantity and in an unlimited number of uses.
              </li>
              <li>
                We are entitled to provide the License or any authorization
                creating a part of the License in full or in part to third
                parties (by the assignment of rights, granting a license or
                sub-license, transfer of rights, or any other form) and we are
                not obliged to inform you of this fact.
              </li>
              <li>
                In particular, we are authorized to (i) use, copy, store,
                perform, display, reproduce, record, play, adapt, modify,
                transmit, distribute and publish on social networks, various
                media and elsewhere any Content, (ii) prepare derivative works
                of the Content (for example changes to your Content as are
                necessary to conform and adapt that Content to the technical
                requirements of connecting networks, devices, services or media)
                or incorporate the Content into other works, and (iii) grant and
                authorize sublicenses of the foregoing in any media now known or
                hereafter created.
              </li>
              <li>
                If you provide, as part of your use of the Services or as part
                of the Content which you submit, post or display on or through
                the Services, any photograph, image, audio, video or other
                materials protected by personality or privacy rights, you
                specifically agree that we may use such photograph or other
                materials for the purposes of providing the Services.
              </li>
              <li>
                You confirm and warrant to us that you have all the rights,
                power and authority necessary to grant the above License and
                consents.
              </li>
              <li>
                The Content can be checked by Cosmic Latte at any time and shall
                be deleted if the Conditions or other rules are violated.
              </li>
            </Ol>

            <H3 numbered>Copyright Infringement</H3>
            <Ol>
              <li>
                If you believe that any material or Content distributed by us
                through the Services constitutes copyright infringement, please
                provide us immediately with the following information:
              </li>
              <ul>
                <li>
                  (a) a description of the copyrighted work that you claim has
                  been infringed
                </li>
                <li>
                  (b) a description of where the copyrighted work that you claim
                  has been infringed is located on our App
                </li>
                <li>
                  (c) your name, surname, address, telephone number and email
                  address; and
                </li>
                <li>
                  (d) a written statement by you that you believe in good faith
                  that the disputed use is not authorized by the copyright
                  owner, its agent, or the law and that your notice is accurate
                  and that you are the copyright owner or are authorized to act
                  on the copyright owner's behalf.
                </li>
              </ul>
              <li>
                Please provide us with the above-mentioned information via the
                App or send it to our e-mail address{' '}
                <a href="mailto:info@zoeapp.co">info@zoeapp.co</a> or to the
                address Cosmic Latte s.r.o., Rohanské nábřeží 678/23, Karlín,
                186 00 Prague 8, Czech Republic.
              </li>
            </Ol>

            <H3 numbered>Impersonation Infringement</H3>
            <Ol>
              <li>
                If you believe that any material or Content distributed by us
                through the Services constitutes impersonation infringement, in
                particular, if any user impersonates any other person or entity
                or otherwise misrepresents affiliation, connection or
                association with any person or entity, for example, by
                displaying pictures or images of another person as profile
                pictures, please provide us immediately with the following
                information:
              </li>
              <ul>
                <li>
                  (a) a description of the Content that you claim has been
                  infringed
                </li>
                <li>
                  (b) a description of where the Content that you claim has been
                  infringed is located on our App
                </li>
                <li>
                  (c) your name, surname, address, telephone number and email
                  address; and
                </li>
                <li>
                  (d) a written statement by you that you believe in good faith
                  that the disputed Content is not authorized by the
                  impersonated individual and that your notice is accurate and
                  that you are the impersonated individual or authorized to act
                  on his or her behalf.
                </li>
              </ul>
              <li>
                Please provide us with the above-mentioned information via the
                App or send it to our e-mail address{' '}
                <a href="mailto:info@zoeapp.co">info@zoeapp.co</a> or to the
                address Cosmic Latte s.r.o., Rohanské nábřeží 678/23, Karlín,
                186 00 Prague 8, Czech Republic.
              </li>
            </Ol>

            <H3 numbered>Terms and Termination</H3>
            <Ol>
              <li>
                We may, in our sole discretion, terminate or suspend your access
                to all or part of the Services or terminate or discontinue the
                Services or the App entirely at any time, with or without
                notice, for any reason, and in particular if
              </li>
              <ul>
                <li>
                  (a) you have breached or intended to breach any provision of
                  the Terms
                </li>
                <li>(b) we are required to do so by law</li>
                <li>
                  (c) we are transitioning to no longer providing the Services
                  to users in the country in which you reside or from which you
                  use the Services; or
                </li>
                <li>
                  (d) the provision of the Services to you by us is, in our
                  opinion, no longer viable.
                </li>
              </ul>
              <li>
                We use Facebook (Meta) for authorization of users. If your
                Facebook account or any other account used for registration
                and/or signing in the Services is deleted or non-functional, you
                may no longer be able to access the Services.
              </li>
              <li>
                Upon such termination or suspension, you will not be entitled to
                any refund of unused fees for in app purchases.
              </li>
              <li>
                We are not required to disclose, and may be prohibited by law
                from disclosing, the reason for the termination or suspension of
                your account.
              </li>
              <li>
                Your right to use the Service is subject to any limitations,
                conditions and restrictions established by us in our sole
                discretion and by applicable law. We may alter, suspend or
                discontinue any aspect of the Service at any time, including the
                availability of any Service feature, database or Content. We may
                also impose limits on certain features and aspects of the
                Service or restrict your access to parts or all of the Service
                without notice or liability and for any reason (most often due
                to violation of conditions, any rights, rules, inappropriate
                behaviour etc.)
              </li>
              <li>
                You acknowledge that we are not responsible for interruption or
                suspension of the Service, regardless of the cause of the
                interruption or suspension.
              </li>
              <li>
                You may also terminate your account at any time, for any reason,
                by following the instructions in Settings in the Service.
              </li>
              <li>
                When your legal agreement with us comes to an end, all of the
                legal rights, obligations and liabilities that you and we have
                benefited from or been subject to and which are expressed to
                continue indefinitely shall be unaffected by this cessation.
              </li>
            </Ol>

            <H3 numbered>Disclaimer</H3>
            <Ol>
              <li>
                You understand and agree that you use the App and Services at
                your own risk.
              </li>
              <li>
                The Services are provided on an "as is" basis without
                representations or warranties of any kind, whether express or
                implied, including but not limited to, warranties of title,
                non-infringement, or implied warranties of merchantability or
                fitness for a particular purpose, other than those warranties
                which are incapable of exclusion under law.
              </li>
              <li>
                We do not guarantee the accuracy, completeness, or timeliness
                of, or otherwise endorse any information contained on the App.
                Due to the number of sources from which the Content distributed
                by us is obtained, there may be delays, omissions or
                inaccuracies in such Content. We do not represent or endorse the
                accuracy or reliability of any user profile, advice, opinion,
                statement or other information displayed, uploaded or
                distributed through the Service. You acknowledge that any
                reliance upon any such Content is at your sole risk.
              </li>
              <li>
                You hereby acknowledge and agree that, under no circumstances,
                will we, our officers, directors, employees, agents and
                third-party Content providers or licensors be liable to you or
                any third party for any damage, whether material, immaterial,
                direct, indirect, incidental, or consequential, including
                personal injury or death, caused by or arising from in
                particular
              </li>
              <ul>
                <li>
                  (a) the use of the Services or reliance on information
                  obtained through the App
                </li>
                <li>
                  (b) the use or inability to use the Service or out of the
                  breach of any warranty
                </li>
                <li>
                  (c) any failure of performance, error, omission, interruption,
                  deletion, defect, delay in operation or transmission, computer
                  virus, communication line failure theft or destruction or
                  unauthorized access to and alteration of the App
                </li>
                <li>
                  (d) incorrect or inaccurate Content posted in the Service,
                  whether caused by users or any of the equipment or programming
                  associated with or utilized in the Service
                </li>
                <li>
                  (e) the timeliness, deletion or removal, incorrect delivery or
                  failure to store any Content or communications
                </li>
                <li>
                  (f) the conduct, whether online or offline, of any user; or
                </li>
                <li>
                  (g) any problems, failure or technical malfunction of any
                  telephone network or lines, computer online systems, servers
                  or providers, computer equipment, software, failure of email
                  or players on account of technical problems or traffic
                  congestion on the Internet or on any website or combination
                  thereof.
                </li>
              </ul>
              <li>
                To the maximum extent permitted by applicable law, you hereby
                agree to indemnify, defend and hold harmless us and all our
                officers, employees, directors, owners, agents, partners,
                information providers, subsidiaries, affiliates, licensors and
                licensees from and against any and all liability, claim, demand
                and costs, including, without limitation, reasonable attorneys'
                fees, made by any third party due to or arising from your breach
                or failure to comply with these Terms, any postings or Content
                you post in the Service and the violation of any law or
                regulation by you. You shall cooperate as fully as reasonably
                required in the defense of any such claim. We reserve the right,
                at our own expense, to assume the exclusive defense and control
                of any matter subject to indemnification by you.
              </li>
              <li>
                To the maximum extent permitted by applicable law, our liability
                for damage incurred by you as a result of or in connection with
                the Services shall be limited to direct damages up to the amount
                you paid to us for the Services giving rise to that liability
                during the last three months before the occurrence of our
                liability (or amount corresponding to a three-month service fee,
                as applicable), and in no event shall our liability exceed the
                sum of USD 10,000.
              </li>
              <li>
                Nothing in these Terms shall exclude or limit our liability for
                damage which may not be lawfully excluded or limited in relation
                to the specific jurisdiction from which the user is resident
              </li>
            </Ol>

            <H3 numbered>Final Provisions</H3>
            <Ol>
              <li>
                The Terms and your relationship with us under the Terms or in
                connection with the Services are governed by Czech law.
              </li>
              <li>
                You agree to submit to the exclusive jurisdiction of the courts
                of the Czech Republic to resolve any disputes arising from the
                Terms or in connection with the Services. The choice of the
                jurisdiction shall not supersede any mandatory consumer
                protection in specific jurisdiction. The users from European
                Union and European Economic Area may, in accordance with
                applicable law, raise claims in their residence country.
              </li>
              <li>
                You agree that any and all disputes arising from or connected to
                the Services shall be resolved individually, without resort to
                any form of class action. No claim may be consolidated or joined
                with another person’s or party’s claims.
              </li>
              <li>
                The Terms are provided in English. You agree that the English
                language version of the Terms will govern your relationship with
                us.
              </li>
              <li>
                The Terms constitute the whole legal agreement between you and
                us and govern your use of the Services and completely replace
                any prior agreements between you and us in relation to the
                Services. You agree that this agreement is enforceable like any
                written agreement signed by you.
              </li>
              <li>
                If any provision of these Terms is found to be unenforceable or
                invalid, that provision shall be limited or eliminated to the
                minimum extent necessary so that these Terms otherwise remain in
                full force and effect and are enforceable.
              </li>
            </Ol>

            <H3 numbered>Contact Information</H3>
            <Ol>
              <li>
                Should you have any questions or comments relating to the Terms,
                please contact us at the following e-mail addresses:{' '}
                <ul>
                  <li>
                    General inquiries:{' '}
                    <a href="mailto:info@zoeapp.co">info@zoeapp.co</a>
                  </li>
                  <li>
                    Customer support:{' '}
                    <a href="mailto:help@zoeapp.co">help@zoeapp.co</a>
                  </li>
                  <li>
                    Law enforcement inquiries:{' '}
                    <a href="mailto:legal@cosmiclatte.com">
                      legal@cosmiclatte.com
                    </a>
                  </li>
                </ul>
                Or to the address Cosmic Latte s.r.o., Rohanské nábřeží 678/23,
                Karlín, 186 00 Prague 8, Czech Republic.
              </li>
              <li>This Terms of Use was last reviewed on 1st August 2024.</li>
            </Ol>
          </Section>
        </PageContent>
      </Container>
    </Page>
  )
}

TermOfUse.propTypes = {
  location: Props.shape({
    search: Props.string,
  }).isRequired,
  pageContext: Props.any.isRequired,
}

export default TermOfUse
